import React, {useEffect, useState} from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import LinkButton from "../components/LinkButton";
import PropTypes from "prop-types";
import {motion, useAnimation} from "framer-motion";
import AppointmentDiv from "../components/AppointmentDiv";
import DivWithText from "../components/DivWithText";
import ExternalLink from "../components/ExternalLink";

const QuestionText = (props) => {
    return (<h5 className="text-2xl mb-2 cursor-pointer" onClick={() => props.onClick && props.onClick()}>{props.children}</h5>)
}

QuestionText.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func
}

const AnswerText = (props) => {
    const controls = useAnimation()
    const [wasOpen, setWasOpen] = useState(false)

    const variants = {
        visible: { height: "auto", opacity: 1, margin: "1.5rem"},
        middle: { height: "auto", opacity: 0, margin: "1.5rem"},
        hidden: { height: 0, opacity: 0, margin: "0rem"},
    }

    const sequence = async () => {
        if (props.isOpen && ! wasOpen) {
            setWasOpen(true)
            await controls.start("middle", {duration: 0.1})
            return await controls.start("visible", {duration: 0.1})
        }
        else if (!props.isOpen && wasOpen) {
            setWasOpen(false)
            await controls.start("middle",{duration: 0.1})
            return await controls.start("hidden",{duration: 0.1})
        }
    }

    useEffect(() => {
        sequence()
    })

    return (
        <motion.div className={"overflow-hidden " + (props.className ?? "")}
                    variants={variants} initial="hidden" animate={controls}>
            {props.children}
        </motion.div>
    )
}

AnswerText.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string
}

const QuestionAndAnswer = (props) => {
    const [isOpen, setOpen] = useState(false)

    const clickHandler = () => {
        setOpen(! isOpen)
    }

    return (
        <>
            <QuestionText onClick={clickHandler}>{isOpen ? '▼' : '▶'} {props.questionText}</QuestionText>
            <AnswerText isOpen={isOpen}>{props.children}</AnswerText>
        </>
    )
}

QuestionAndAnswer.propTypes = {
    questionText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

function FrequentlyAskedQuestionsPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Frequently Asked Questions`, `FAQ`]}
                title="Frequently Asked Questions"
                description="Frequently Asked Questions about Agile Psychiatry and Brian Nahas, MD"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv className="w-full">
                        <h2 className="text-3xl text-center mb-8">Frequently Asked Questions</h2>

                        <h2 className="text-2xl text-center mb-8">Emergencies</h2>
                        <QuestionAndAnswer questionText="What do I do if I am having an emergency right now?">
                            <TextParagraph>
                                If you are having an emergency, please call 9-1-1.

                                {/*if the situation is life threatening to yourself or others. If there is no*/}
                                {/*immediate physical danger, please contact the clinic. Additionally you can take advantage*/}
                                {/*of the <ExternalLink to="https://hhs.texas.gov/services/mental-health-substance-use/mental-health-crisis-services">crisis resources</ExternalLink> outlined*/}
                                {/*on the Texas Health and Human Services website.*/}
                            </TextParagraph>
                        </QuestionAndAnswer>

                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-light"/>
            <Section shade="light">
                <SectionRow>
                    <TextDiv className="m-auto">
                        <h2 className="text-2xl text-center mb-8">Appointments</h2>

                        <QuestionAndAnswer questionText="Does Agile Psychiatry take insurance?">
                            <TextParagraph className="mb-4">
                                At this time, Agile Psychiatry does not take insurance, Medicare, or Medicaid. However,
                                you may be able to get reimbursed for your appointments at Agile Psychiatry through your insurance provider
                                by filing a claim on your own using a &quot;Super Bill&quot; provided to you after your appointment.
                                A &quot;Super Bill&quot; is a document that includes the necessary information
                                (cost, charge codes, etc) for your insurance provider to reimburse you for the appointment.
                            </TextParagraph>
                            <TextParagraph className="mb-4">
                                In this case, Agile Psychiatry will likely be classified as an &quot;Out of Network&quot; provider
                                for reimbursement purposes. The amount reimbursed is determined by your insurance benefits and how
                                much is covered
                                for &quot;Out of Network&quot; providers like Agile Psychiatry. If you have questions about
                                your &quot;Out of Network&quot; benefits,
                                please contact your insurance plan provider.
                            </TextParagraph>
                            <TextParagraph>
                                You can still use your insurance for medications and lab work if it provides coverage
                                for them.
                            </TextParagraph>
                        </QuestionAndAnswer>

                        <QuestionAndAnswer questionText="How do I schedule an appointment?">
                            <DivWithText>
                                Please go to the <LinkButton to="/appointments" className="inline-block">Appointments</LinkButton> page and follow the directions outlined there.
                            </DivWithText>
                        </QuestionAndAnswer>

                        <QuestionAndAnswer questionText="How soon can I get an appointment?">
                            <TextParagraph>
                                Dr. Nahas is closing his clinical practice at Agile Psychiatry, effective 1/31/23. Therefore he
                                is not accepting new patients at this time.
                            </TextParagraph>
                            {/*<TextParagraph>*/}
                            {/*    At this time, Dr. Nahas is not accepting new patients. Please check back in the future*/}
                            {/*    if you continue to be interested in an appointment.*/}
                            {/*</TextParagraph>*/}
                            {/*<TextParagraph>*/}
                            {/*    Typically, patients can be seen by Dr. Nahas within 1-2 weeks after submitting their*/}
                            {/*    appointment request.*/}
                            {/*</TextParagraph>*/}
                        </QuestionAndAnswer>

                        <QuestionAndAnswer questionText="What if I need to cancel or reschedule an appointment?">
                            <TextParagraph className="mb-4">
                                You will not be charged if you cancel your appointment with at least 48 business hours notice.
                                Due to the challenges of filling a slot on short notice, 50% of the fee will be charged if you
                                cancel less than 48 business hours beforehand.
                                {/* TODO: Need to clarify weekends */}
                            </TextParagraph>
                            <TextParagraph>
                                For example, if an appointment is on Monday at 4pm, you will need to communicate
                                cancellation no later than the previous Thursday at 4pm; if an appointment is on
                                Wednesday at 10am, you will need to communicate no later than Monday at 10am.
                                {/* TODO: Need to clarify weekends */}
                            </TextParagraph>
                        </QuestionAndAnswer>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="light-mid"/>
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-2xl text-center mb-8">Services</h2>

                        <QuestionAndAnswer questionText="What services are not provided via telepsychiatry?">
                            <TextParagraph className="mb-4">
                                Due to safety concerns, certain conditions are not suitable to be treated solely via
                                telepsychiatry. Dr. Nahas will discuss with you whether Agile Psychiatry services
                                are suitable for you during the initial phone call after you submit an appointment
                                request.
                            </TextParagraph>
                            <TextParagraph>
                                Additionally, due to regulatory requirements we cannot prescribe controlled substances
                                to patients who are seen exclusively via telepsychiatry. For a list of controlled substances
                                please see the <ExternalLink to="https://www.deadiversion.usdoj.gov/schedules/">DEA&apos;s List of Controlled Substances</ExternalLink>.
                            </TextParagraph>
                            <TextParagraph>
                                If you have concerns about these items, please discuss this with Dr. Nahas during
                                your initial phone call.
                            </TextParagraph>
                        </QuestionAndAnswer>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
        </LayoutGeneral>
    );
}

export default FrequentlyAskedQuestionsPage;
